import Router from "next/router";
import useSWR from "swr";

import type { FetcherError } from "@/lib/fetch";
import { fetcher } from "@/lib/fetch";
import { useNotificationsStore } from "@/stores/notificationsStore";
import { useRouter } from "next/router";

interface User {
  id: number;
  authorized: boolean;
}

export function useUser(options?: {
  redirectTo?: string;
  redirectIfFound?: boolean;
  shouldRetryOnError?: boolean;
}) {
  const router = useRouter();
  const showErrorNotification = useNotificationsStore(state => state.showErrorNotification);
  const {
    data: user,
    error,
    isLoading,
    mutate,
  } = useSWR<Partial<User>, FetcherError>("/api/auth", fetcher, {
    shouldRetryOnError: options?.shouldRetryOnError,
    revalidateOnMount: options?.shouldRetryOnError,
    revalidateOnFocus: options?.shouldRetryOnError,
    revalidateOnReconnect: options?.shouldRetryOnError,
  });

  async function login(userID: number) {
    await mutate({ authorized: true, id: userID });
  }

  async function logout() {
    const res = await fetch("/api/auth/logout", {
      method: "post",
      credentials: "same-origin",
    });
    if (res.ok) {
      await mutate({ authorized: false });
      await Router.push("/");
    } else {
      showErrorNotification(await res.text());
    }
  }

  if (
    (!options?.redirectIfFound &&
      options?.redirectTo &&
      (user?.authorized === false || error?.status === 401)) ||
    (options?.redirectIfFound && user?.authorized)
  ) {
    if (options.redirectTo) {
      if (!router.asPath.includes("[")) {
        Router.push(options.redirectTo);
      }
    }
  }

  return {
    id: user?.id,
    authorized: user?.authorized,
    isLoading,
    isError: error,
    logout,
    login,
  };
}
