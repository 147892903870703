import { SiFacebook, SiInstagram, SiX } from "@icons-pack/react-simple-icons";
import { cx } from "classix";

import CustomLink from "@/components/CustomLink";
import { t } from "@/locales/translate";

function Footer({ className }: { className?: string }) {
  const currentYear = new Date().getFullYear().toString();
  return (
    <footer className={cx("bg-background mt-auto pb-2 pt-4", className)}>
      <div className="flex flex-col items-center justify-center space-y-2 md:flex-row md:space-x-8 md:space-y-0">
        <CustomLink href="/privacy">{t("footer.privacy")}</CustomLink>
        {/* <LanguageSwitch /> */}
        <div className="flex items-center space-x-2">
          <SiX className="h-4 w-4" />
          <CustomLink href="https://twitter.com/FantaAsta" rel="noopener noreferrer nofollow">
            FantaAsta
          </CustomLink>
        </div>
        <div className="flex items-center space-x-2">
          <SiInstagram className="h-4 w-4" />{" "}
          <CustomLink
            href="https://www.instagram.com/fantaasta_/"
            rel="noopener noreferrer nofollow"
          >
            fantaasta_
          </CustomLink>
        </div>
        <div className="flex items-center space-x-2">
          <SiFacebook className="h-4 w-4" />{" "}
          <CustomLink
            href="https://www.facebook.com/FantaAsta-103787325369665"
            rel="noopener noreferrer nofollow"
          >
            FantaAsta
          </CustomLink>
        </div>
      </div>
      <div className="mt-4 flex items-center justify-center">
        <small className="text-neutral-600 dark:text-neutral-400">
          {t("footer.copyright", { currentYear })}{" "}
          <CustomLink key="customlink" href="https://tommasoamici.com" rel="noopener">
            Tommaso Amici
          </CustomLink>
        </small>
      </div>
    </footer>
  );
}

export default Footer;
