import { cx } from "classix";
import Link from "next/link";
import { ComponentProps } from "react";

const CustomLink = (props: ComponentProps<typeof Link>) => {
  return (
    <Link
      {...props}
      className={cx(
        "text-primary-700 dark:text-primary-500 hover:text-primary-600 dark:hover:text-primary-400 focus:ring-brand dark:focus:ring-brand-dark cursor-pointer transition-colors hover:underline focus:outline-none focus:ring focus:ring-opacity-50",
        props.className,
      )}
    >
      {props.children}
    </Link>
  );
};
CustomLink.displayName = "CustomLink";
export default CustomLink;
