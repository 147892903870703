import { t } from "@/locales/translate";
import { DefaultSeo } from "next-seo";

const DefaultSEO = () => {
  return (
    <DefaultSeo
      openGraph={{
        type: "website",
        url: "https://fantaasta.com/",
        site_name: "FantaAsta",
        title: "FantaAsta",
        description: t("seo.description"),
        images: [{ url: "https://fantaasta.com/meta.jpg" }],
      }}
      title={undefined}
      titleTemplate={"%s | FantaAsta"}
      defaultTitle={"FantaAsta"}
      description={t("seo.description")}
      twitter={{ site: "@FantaAsta", handle: "@tamici_", cardType: "summary" }}
    />
  );
};

export default DefaultSEO;
