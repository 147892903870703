import { create } from "zustand";

interface Notification {
  message: string;
  status: number;
  timestamp: number;
}

type NotificationsStore = {
  notifications: Notification[];
  showNotification: (message: string, status: number) => void;
  showInfoNotification: (message: string) => void;
  showSucessNotification: (message: string) => void;
  showWarningNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
  remove: (timestamp: number) => void;
};

export const useNotificationsStore = create<NotificationsStore>()(set => ({
  notifications: [],
  showNotification: (message: string, status: number) =>
    set(state => ({
      notifications: [...state.notifications, { message, status, timestamp: Date.now() }],
    })),
  showInfoNotification: (message: string) =>
    set(state => ({
      notifications: [...state.notifications, { message, status: 100, timestamp: Date.now() }],
    })),
  showSucessNotification: (message: string) =>
    set(state => ({
      notifications: [...state.notifications, { message, status: 200, timestamp: Date.now() }],
    })),
  showWarningNotification: (message: string) =>
    set(state => ({
      notifications: [...state.notifications, { message, status: 400, timestamp: Date.now() }],
    })),
  showErrorNotification: (message: string) =>
    set(state => ({
      notifications: [...state.notifications, { message, status: 500, timestamp: Date.now() }],
    })),
  remove: (timestamp: number) =>
    set(state => ({
      notifications: state.notifications.filter(
        notification => notification.timestamp !== timestamp,
      ),
    })),
}));
