import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";

import { NavbarButton } from "@/components/Navbar";
import { useUser } from "@/hooks/useUser";
import { t } from "@/locales/translate";

const LogoutButton = () => {
  const { logout } = useUser();
  return (
    <NavbarButton onClick={logout}>
      <span className="sr-only">{t("navbar.logout")}</span>
      <ArrowLeftOnRectangleIcon height={24} width={24} aria-hidden={true} />
    </NavbarButton>
  );
};
export default LogoutButton;
