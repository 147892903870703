export const baseOptions: RequestInit = {
  credentials: "include",
  redirect: "follow",
  headers: {
    "Content-Type": "application/json",
  },
};

export const post = (url: string, body?: object) =>
  fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    ...baseOptions,
  });

export const put = (url: string, body: object) =>
  fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    ...baseOptions,
  });

export const patch = (url: string, body: object) =>
  fetch(url, {
    method: "PATCH",
    body: JSON.stringify(body),
    ...baseOptions,
  });

export const fetchDelete = (url: string, body?: object) =>
  fetch(url, {
    method: "DELETE",
    body: JSON.stringify(body),
    ...baseOptions,
  });

export type FetcherError = Error & {
  info: string;
  status: number;
};

export async function fetcher(url: string) {
  const res = await fetch(url);
  if (res.ok) {
    const json = await res.json();
    return json;
  } else {
    const error = new Error("Failed to get resource");
    // @ts-expect-error
    error.status = res.status;
    // @ts-expect-error
    error.info = await res.text();
    throw error;
  }
}
