import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import { NavbarButton } from "@/components/Navbar";

export function wantsDarkMode() {
  return (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
  );
}

export const DarkModeToggle = () => {
  const [dark, setDark] = useState(false);

  useEffect(() => {
    setDark(wantsDarkMode());
  }, []);

  if (dark) {
    return (
      <NavbarButton
        onClick={() => {
          setDark(false);
          localStorage.theme = "light";
          document.documentElement.classList.remove("dark");
        }}
      >
        <SunIcon width={24} height={24} className="h-6 w-6" />
      </NavbarButton>
    );
  } else {
    return (
      <NavbarButton
        onClick={() => {
          setDark(true);
          localStorage.theme = "dark";
          document.documentElement.classList.add("dark");
        }}
      >
        <MoonIcon width={24} height={24} className="h-6 w-6" />
      </NavbarButton>
    );
  }
};

DarkModeToggle.displayName = "DarkModeToggle";
