import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { cx } from "classix";

import { useMountEffect } from "@/hooks/useMountEffect";
import { useNotificationsStore } from "@/stores/notificationsStore";

export const styleFromStatus = (status: number) => {
  if (status < 200) {
    return "bg-blue-50 text-blue-700 dark:bg-blue-700 dark:text-blue-50";
  } else if (status < 300) {
    return "bg-success-50 text-success-700 dark:bg-success-700 dark:text-success-50";
  } else if (status < 500) {
    return "bg-warning-50 text-warning-700 dark:bg-warning-700 dark:text-warning-50";
  } else {
    return "bg-error-50 text-error-700 dark:bg-error-700 dark:text-error-50";
  }
};

export const progressBarBackgroundFromStatus = (status: number) => {
  if (status < 200) {
    return "bg-blue-500";
  } else if (status < 300) {
    return "bg-success-500";
  } else if (status < 500) {
    return "bg-warning-500";
  } else {
    return "bg-error-500";
  }
};

export const NotificationIcon = ({ status }: { status: number }) => {
  let Icon: typeof InformationCircleIcon;
  if (status < 200) {
    Icon = InformationCircleIcon;
  } else if (status < 300) {
    Icon = CheckCircleIcon;
  } else if (status < 500) {
    Icon = ExclamationTriangleIcon;
  } else {
    Icon = ExclamationCircleIcon;
  }
  return <Icon width={20} height={20} className="pointer-events-none h-5 w-5 shrink-0" />;
};

export const Notification = ({ message }: { message: INotification }) => {
  const remove = useNotificationsStore(state => state.remove);

  useMountEffect(() => {
    const timer = setTimeout(() => {
      remove(message.timestamp);
    }, 5000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="relative">
      <div
        className={cx(
          styleFromStatus(message.status),
          "flex w-80 items-center justify-between rounded py-2 pl-2 pr-4",
        )}
        role="alert"
      >
        <div className="flex items-center space-x-2">
          <NotificationIcon status={message.status} />
          <p>{message.message}</p>
        </div>
        <button
          onClick={e => {
            e.preventDefault();
            remove(message.timestamp);
          }}
        >
          <XMarkIcon width={16} height={16} className="h-4 w-4 opacity-60 hover:opacity-100" />
        </button>
      </div>
      <div
        className={cx(
          "motion-safe:animate-collapse absolute bottom-0 h-1 rounded-bl",
          progressBarBackgroundFromStatus(message.status),
        )}
      />
    </div>
  );
};

export const Notifications = () => {
  const notifications = useNotificationsStore(state => state.notifications);
  const notificationsEls = notifications.map(m => <Notification key={m.timestamp} message={m} />);
  return <div className="fixed right-0 top-0 z-50 m-4 space-y-2">{notificationsEls}</div>;
};
