import locale from "./it.json";

type Prefix<S extends string> = S extends "" ? "" : `${S}.`;

/**
 * Defines a recursive conditional type to convert an object with nested keys to
 * a union of strings representing the paths
 *
 * @example NestedKeysToPaths<{ a: { b: { c: string } } }> = "a" | "a.b" | "a.b.c"
 */
type NestedKeysToPaths<T, Key extends string = ""> = {
  [K in keyof T]: K extends string
    ? T[K] extends object
      ? T[K] extends { one: string; other: string }
        ? `${Prefix<Key>}${K}`
        : NestedKeysToPaths<T[K], `${Prefix<Key>}${K}`>
      : `${Prefix<Key>}${K}`
    : never;
}[keyof T];

type LocaleKeys = NestedKeysToPaths<typeof locale>;

export function t<TranslationValues extends object & { count?: number }>(
  key: LocaleKeys,
  values?: TranslationValues,
) {
  let value: string | undefined = undefined;
  for (const k of key.split(".")) {
    // @ts-expect-error
    value = value ? value[k] : locale[k];
  }
  if (!value) {
    throw new Error(`Missing translation for key "${key}"`);
  }
  if (values === undefined) {
    return value;
  }

  if (values.count !== undefined) {
    const cardinalRules = new Intl.PluralRules("it-IT");
    // @ts-expect-error
    value = value[cardinalRules.select(values.count)];
    if (!value) {
      throw new Error(`Missing plurarl options for key "${key}"`);
    }
  }

  for (const [k, v] of Object.entries(values)) {
    value = value.replace(`{{${k}}}`, v?.toString());
  }

  return value;
}
