import { t } from "@/locales/translate";
import { ArrowRightOnRectangleIcon, CogIcon } from "@heroicons/react/24/outline";
import Link from "next/link";

import logoSmall from "../../public/logo-small.svg";
import logo from "../../public/logo.svg";

import { DarkModeToggle } from "./DarkModeToggle";

import LogoutButton from "@/components/LogoutButton";
import { useUser } from "@/hooks/useUser";
import cx from "classix";
import { tw } from "tw-lite";

export const NavbarLink = tw(Link)`
  cursor-pointer focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50
`;

export const NavbarButton = tw("button")`
  cursor-pointer focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50
`;

type Props = {
  className?: string;
};

const Navbar = ({ className }: Props) => {
  const { authorized } = useUser();

  return (
    <div
      className={cx("bg-brand relative z-50 flex h-16 shrink-0 items-center text-white", className)}
    >
      <nav className="container mx-auto flex items-center justify-between">
        <div className="flex items-center justify-between">
          <Link href={authorized ? "/home" : "/"} aria-label={t("navbar.goHome")}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img className={"mr-4 hidden h-9 md:block"} src={logo.src} alt={"FantaAsta"} />
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img className={"mr-4 w-16 md:hidden"} src={logoSmall.src} alt={"FantaAsta"} />
          </Link>
          <ul className="flex items-center justify-between space-x-4">
            {authorized && (
              <li>
                <NavbarLink href="/leagues">{t("leagues.title")}</NavbarLink>
              </li>
            )}
            <li>
              <NavbarLink href="/planner">{t("navbar.planner")}</NavbarLink>
            </li>
          </ul>
        </div>
        <div className="flex items-center space-x-4">
          {authorized ? (
            <>
              <LogoutButton />
              <NavbarLink href="/settings">
                <span className="sr-only">{t("navbar.settings")}</span>
                <CogIcon height={24} width={24} aria-hidden={true} />
              </NavbarLink>
            </>
          ) : (
            <NavbarLink href="/login">
              <span className="sr-only">{t("navbar.login")}</span>
              <ArrowRightOnRectangleIcon height={24} width={24} aria-hidden={true} />
            </NavbarLink>
          )}
          <DarkModeToggle />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
